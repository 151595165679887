<script>
import { defineComponent, ref, computed, watch } from 'vue'

/**
 * @param {ProductFormModel['images'][0]} image 
 */
const getImageUrl = (image) => {
  if (image.url) return `${image.url}?tr=w-200,h-200,cm-pad_resize,bg-FFFFFF`
  if (image.file) return URL.createObjectURL(image.file)

  return null
}

export default defineComponent({
  props: {
    images: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selected = ref([...props.value])
    const imageList = computed(
      () => props.images.filter(image => !image.deleted)
        .map(image => ({
          refId: image.ref_id,
          url: image.url,
          thumbnailUrl: getImageUrl(image),
          selectedIndex: selected.value.findIndex(({ refId }) => refId === image.ref_id),
        })),
    )

    const select = (image) => {
      if (image.selectedIndex > -1) {
        selected.value = selected.value.filter(({ refId }) => refId !== image.refId)
      } else {
        selected.value.push({
          refId: image.refId,
          imagePath: image.url,
        })
      }
    }

    const updateImage = () => {
      emit('input', selected.value)
      modal.value = false
    }

    const modal = ref(false)
    watch(modal, () => selected.value = [...props.value])

    const preview = computed(() => props.value.length ? props.value[0] : null)

    const scrollToPhotoAndVideo = () => {
      window.scrollTo({
        top: 960,
        behavior: 'smooth',
      })
      modal.value = false
    }
    
    return {
      selected,
      imageList,
      select,
      modal,
      updateImage,
      preview,
      scrollToPhotoAndVideo,
    }
  },
})
</script>

<template>
  <div>
    <div class="product-variant--image-placeholder" @click="modal = true">
      <img
        v-if="preview"
        :src="`${preview.imagePath}?tr=w-150,h-150,cm-pad_resize,bg-FFFFFF`"
        alt="preview"
        class=""
        draggable="false"
      >
      <div v-else>
        <a-icon type="plus" />
        Pilih gambar produk
      </div>
    </div>
    
    <a-modal
      v-model="modal"
      title="Select photo"
      :ok-button-props="{ props: { disabled: !imageList.length } }"
      @ok="updateImage"
    >
      <div v-if="imageList && imageList.length" class="product-variant--image-selector">
        <div 
          v-for="image in imageList"
          :key="image.refId"
          :class="{ selected: image.selectedIndex > -1, 'image-item': true }"
        >
          <div @click="select(image)">
            <img :src="image.thumbnailUrl" class="w-100" alt="thumb" draggable="false">
          </div>
          <div v-if="image.selectedIndex > -1" class="checker">
            <div>{{ image.selectedIndex + 1 }}</div>
          </div>
        </div>
      </div>
      <a-empty v-else>
        <span slot="description">Silahkan upload gambar di section <b>Foto dan Video</b></span>
        <a-button type="primary" icon="plus" @click="scrollToPhotoAndVideo">
          Gambar
        </a-button>
      </a-empty>
    </a-modal>
  </div>
</template>

<style lang="scss">
.product-variant--image-placeholder {
  overflow: hidden;
  padding-top: 1rem;
  cursor: pointer;
  margin-top: .5rem;

  > img,
  > div {
    width: 125px;
    height: 125px;
    border-radius: 4px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border: 1px dashed #ccc;
    background-color: #fff;
    color: #999;
    font-size: .8rem;
    padding: .5rem;

    .anticon {
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }

    &:hover {
      background-color: #f7f9fc;
    }
  }
}

.has-feedback.has-error {
  .product-variant--image-placeholder {
    > div {
      border-color: var(--danger);
      color: var(--danger);
    }
  }
}

.product-variant--image-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -.5rem;
  margin-right: -.5rem;

  > .image-item {
    position: relative;
    border: 1px solid #ccc;
    border-radius: .25rem;
    overflow: hidden;
    width: 105px;
    height: 105px;
    margin-bottom: 1rem;
    margin-left: .5rem;
    margin-right: .5rem;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    .checker {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      background-color: var(--kit-color-primary);
      color: white;
      opacity: 0;
      transition: all .1s ease-in-out;
      font-size: 80%;
    }


    &.selected {
      outline: 1px var(--kit-color-primary) solid;
      border-color: var(--kit-color-primary);

      .checker {
        opacity: 1;
      }
    }
  }
}
</style>
