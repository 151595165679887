<script>
import { defineComponent } from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useSaveSectionOnScroll } from '../useSaveSectionOnScroll'
import WarehouseAndInventoryForm from './Form.vue'

export default defineComponent({
  components: {
    WarehouseAndInventoryForm,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    warehouses: {
      type: Array,
      required: true,
    },
    inventoryList: {
      type: Array,
      required: true,
    },
    /** @type {ProductFormModel} */
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { dirty, el, validationObserver} = useSaveSectionOnScroll(emit)

    return {
      el,
      dirty,
      validationObserver,
    }
  },
  computed: {
    restrictionList() {
      const findRestrictionList = this.$store.state.user.user_workspaces.find((obj) => obj.workspace_id === this.$route.query.workspace_id)
      if (findRestrictionList?.restriction_list?.length > 0) {
        return findRestrictionList?.restriction_list
      } else {
        return []
      }
    },
  },
})
</script>

<template>
  <a-card ref="el">
    <h4 class="mb-5">
      Inventories
    </h4>

    <ValidationObserver ref="validationObserver" rules="required">
      <ValidationProvider
        v-slot="{ errors }"
        name="Inventories"
        rules="required"
        :messages="{
          required: 'Pilih salah satu gudang untuk produk ini'
        }"
      >
        <WarehouseAndInventoryForm
          :value="value.inventories"
          :warehouses="warehouses"
          :inventory-list="inventoryList"
          :restriction-base="$store.state.user.restriction_base"
          :restriction-list="restrictionList"
          :catalog-items="value.catalogs.items"
          @input="(v) => $emit('input', { ...$props.value, inventories: v}) "
        />
        <div v-if="errors.length" class="text-danger mt-2">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </ValidationObserver>
  </a-card>
</template>
