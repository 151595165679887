<template>
  <div>
    <template v-if="errors.code">
      <a-result :status="String(errors.code)" :title="errors.code" :sub-title="errors.message">
        <template #extra>
          <a-button type="primary" @click="$router.back()">
            Back to previous page
          </a-button>
        </template>
      </a-result>
    </template>
    <template v-else>
      <div
        class="mb-4"
        style="position: sticky; top: 78px; z-index: 5; background-color: white; margin: -20px -35px 0; padding: 15px 30px;"
      >
        <a-breadcrumb
          separator=">"
        >
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.title') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.listProduct') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            {{ business.business_title }}
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            Ubah Produk
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <CreateProductForm
        ref="form"
        :business="business"
        :product="product"
        :permission="permission"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getProductDetail } from '@/api/product'
import CreateProductForm from '@/components/Product/ProductForm/index.vue'

export default defineComponent({
  name: 'EditProductPage',
  components: { CreateProductForm },
  beforeRouteLeave(to, from, next) {
    return this.$refs.form ? this.$refs.form.beforeRouteLeave(to, from, next) : next()
  },
  data() {
    return {
      product: null,
      errors: {
        message: null,
        code: null,
      },
    }
  },
  computed: {
    productId() {
      const id = this.$route.query.id
      return Array.isArray(id) ? id.join(',') : id
    },
    
    /**
     * @returns {string}
     */
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : String(this.$route.query['business_id'] || '')
    },

    /**
     * @returns {import('@/types/utils').Business}
     */
    business() {
      return this.$store.state.user.businessList.find(business => business.business_id === this.businessId) || {}
    },
    permission() {
      return this.$store.getters['user/can']('product-master')
    },
  },
  // watch: {
  //   permission(newValue) {
  //     if(!newValue.length) {
  //       this.$router.push({path: '/error/403', query: {...this.$route.query}})
  //     }
  //   },
  // },
  mounted() {
    this.$store.commit('products/SET_CHANNEL', false)
    this.fetchProductDetail()
  },
  methods: {
    async fetchProductDetail() {
      if (!this.productId) return

      this.errors.message = null
      
      try {
        const { data: { data } } = await getProductDetail({ 
          id: this.productId,
          params: { business_id: this.businessId },
        })

        this.product = data
      } catch (err) {
        const code = err.response?.status
        this.errors.code = code
        this.errors.message = code === 404 ? 'Produk tidak ditemukan' : (err.response?.data?.message || 'Terjadi kesalahan')
      }
    },
  },
})
</script>
