<script>
import { getCurrentInstance, defineComponent, ref, computed, reactive, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { getChannelCategories, getChannelAttributes } from '@/api/channels/index'
import buildTree from '@/utils/buildTree'
import FormItem from '@/components/Input/FormItem.vue'

const SUPPORTED_CHANNEL_CODE = [
  /webcommerce/,
  /distributor/,
  /store/,
  /distributor_store/,
  /dropship/,
  /fund_raiser/,
  /b2b/,
  /tiktok/,
  /tokopedia/,
  /shopee/,
  // /shopee_(id|sg|my)/i,
]

export default defineComponent({
  components: {
    FormItem,
    ValidationObserver,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    channelCategories: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { $route, $moment } = getCurrentInstance().proxy.$root
    const model = ref([])
    const marketplaceCode = ref(['shopee', 'shopee_id', 'tiktok', 'tiktok_id', 'tokopedia', 'tokopedia_id'])
    const marketplaceList = ref([])
    const categoryList = reactive({})
    const isMultiple = ref(['MULTIPLE_SELECT', 'MULTIPLE_SELECT_COMBO_BOX'])

    watch(() => props.value, () => {
      model.value = props.value.map(({ deleted, ...item }) => ({ ...item, deleted: deleted === undefined ? false : deleted }))
    }, { immediate: true })

    watch(() => props.channelCategories, (category) => {
      marketplaceList.value.forEach((mp, index) => {
        mp.category = []
        mp.attribute = []
        mp.attributes = []
        mp.isMapping = false
        if(category?.[mp.sales]) {
          mappingCategories(categoryList[mp.sales], category[mp.sales], index)
        }
      })
    })

    watch(() => props.product.detail.brand_id, (brand) => {
      selectBrandAttribute(brand)
    })

    const activeChannels = computed(() => model.value.filter(item => !item.deleted))
    const modelIds = computed(() => activeChannels.value.map(item => item.id))
    const unselectedChannels = computed(
      () => props.channels.filter(c => !modelIds.value.includes(c.id) && c.status.toLowerCase() === 'connected')
        .map(c => ({
          ...c,
          supported: isSupportedChannel(c),
        }))
        .sort((a, b) => b.supported - a.supported),
    )

    const addChannelModal = reactive({
      open: false,
      selected: [],
    })
    watch(() => addChannelModal.open, () => {
      addChannelModal.selected = []
    })

    const selectChannel = (channelId) => {
      const exists = addChannelModal.selected.includes(channelId)
      if (exists) {
        addChannelModal.selected = addChannelModal.selected.filter(c => c !== channelId)
      } else {
        addChannelModal.selected.push(channelId)
      }
    }
    const addChannel = () => {
      model.value.push(...addChannelModal.selected.map(channelId => {
        const foundChannel = props.channels.find(c => c.id === channelId) || {}
        if(foundChannel?.code && marketplaceCode.value.includes(foundChannel.code)) {
          const { id, code, sales } = foundChannel
          marketplaceList.value.push({
            id,
            code,
            sales,
            category: [],
            categories: [],
            attribute: [],
            attributes: [],
            fields: code.includes('shopee')
              ? { label: 'display_category_name', value: 'category_id', children: 'children' }
              : { label: 'name', value: 'category_id', children: 'children' },
            isMapping: false,
            loading: true,
            attrLoading: false,
          })

          addCategory({ id, code, sales }, (marketplaceList.value.length - 1))
        }

        return {
          ...foundChannel,
          deleted: false,
          exist: false,
        }
      }))
      addChannelModal.open = false
      emit('input', model.value)
    }
    const removeChannel = (channelId) => {
      const channel = activeChannels.value.find(c => c.id === channelId)
      channel.deleted = true
      const index = marketplaceList.value.findIndex(mp => mp.id == channelId)
      if(index > -1) {
        marketplaceList.value.splice(index, 1)
        emitValue()
      }
      emit('input', model.value)
    }

    const isSupportedChannel = (channel) => {
      return SUPPORTED_CHANNEL_CODE.some(pattern => pattern.test(channel.code))
    }

    const mappingCategories = (categories, keyword, index) => {
      const findCategory = categories.find(category => category.category_id == keyword)
      if(findCategory?.category_id) {
        marketplaceList.value[index].category.unshift(findCategory.category_id)
        if(findCategory.parent_category_id == 0) {
          marketplaceList.value[index].isMapping = true
          fetchAttributes(marketplaceList.value[index].category, marketplaceList.value[index])
        } else {
          mappingCategories(categories, findCategory.parent_category_id, index)
        }
      }
    }

    const addCategory = async (channel, index) => {
      await getChannelCategories({
        business_id: props.businessId,
        channel_code: channel.code,
        channel_id: channel.id,
      })
      .then(({ data }) => {
        const list = channel.code.includes('shopee')
          ? data
          : channel.code.includes('tokopedia')
          ? data.category_list
          : data.data.category_list
        const parent_id = channel.code.includes('shopee')
          ? 'parent_category_id'
          : 'parent_id'
        categoryList[channel.sales] = list
        marketplaceList.value[index].categories = buildTree(list, 'category_id', parent_id)
        if(props.channelCategories?.[channel.sales]) {
          mappingCategories(list, props.channelCategories[channel.sales], index)
        }
      })
      .finally(() => marketplaceList.value[index].loading = false)
    }

    const fetchAttributes = async (category, channel) => {
      channel.attribute = []
      channel.attributes = []
      if(category.length) {
        channel.attrLoading = true
        const bodyVal = {
          business_id: props.businessId,
          channel_code: channel.code,
          channel_id: channel.id,
          channel_type: channel.code.includes('tokopedia')
            ? 'annotation'
            : 'attributes',
          params: {
            shop_id: channel.code.includes('tiktok')
              ? 'c9ee7774-8494-45e3-8398-bf3e528be375'
              : undefined,
            category_id: category[3] || category[2] || category[1] || category[0],
          },
        }
        
        await getChannelAttributes(bodyVal)
          .then(({ data }) => {
            let arrMandatory = []
            let arrNotMandatory = []
            if(channel.code.includes('shopee')) {
              const list = data ? data : []
              list?.forEach(attr => {
                const unit = attr.format_type === 'QUANTITATIVE' && attr.attribute_unit.length
                  ? attr.attribute_unit[0]
                  : undefined
                const obj = {
                  ...attr,
                  attribute_type: undefined,
                  value: undefined,
                  mode: isMultiple.value.includes(attr.input_type)
                    ? 'multiple'
                    : 'default',
                  unit,
                }
                if(attr.is_mandatory) {
                  arrMandatory.push(obj)
                } else {
                  arrNotMandatory.push(obj)
                }
              })
              channel.attributes = arrMandatory.concat(arrNotMandatory)
            } else if(channel.code.includes('tokopedia')) {
              const list = data?.data ? data.data : []
              list?.forEach((attr, index) => {
                const obj = {
                  attribute_type: undefined,
                  attribute_id: index,
                  original_attribute_name: attr.variant,
                  display_attribute_name: attr.variant,
                  is_mandatory: false,
                  format_type: 'NORMAL',
                  input_validation_type: 'STRING_TYPE',
                  mode: 'default',
                  input_type: 'COMBO_BOX',
                  max_input_value_number: 1,
                  attribute_value_list: attr?.values?.length
                    ? attr.values.map(val => {
                        return {
                          value_id: val.id,
                          original_value_name: val.name,
                          display_value_name: val.name,
                        }
                      })
                    : [],
                }
                arrNotMandatory.push(obj)
              })
              channel.attributes = arrMandatory.concat(arrNotMandatory)
              selectBrandAttribute(props.product.detail.brand_id)
            } else {
              const list = data?.data?.attributes ? data.data.attributes : []
              list?.forEach(attr => {
                if(attr.attribute_type == '3') {
                  const obj = {
                    attribute_type: attr.attribute_type,
                    attribute_id: attr.id,
                    original_attribute_name: attr.name,
                    display_attribute_name: attr.name,
                    is_mandatory: attr.input_type.is_mandatory,
                    format_type: 'NORMAL',
                    input_validation_type: 'STRING_TYPE',
                    mode: attr.input_type.is_multiple_selected && !attr?.values?.length
                      ? 'tags'
                      : attr.input_type.is_multiple_selected && attr?.values?.length
                      ? 'multiple'
                      : 'default',
                    input_type: attr.input_type.is_multiple_selected
                      ? 'MULTIPLE_SELECT_COMBO_BOX'
                      : !attr.input_type.is_multiple_selected && !attr?.values?.length
                      ? 'TEXT_FILED'
                      : 'COMBO_BOX',
                    max_input_value_number: attr.input_type.is_multiple_selected
                      ? 20
                      : 1,
                    attribute_value_list: attr?.values?.length
                      ? attr.values.map(val => {
                          return {
                            value_id: val.id,
                            original_value_name: val.name,
                            display_value_name: val.name,
                          }
                        })
                      : [],
                  }
                  if(attr.input_type.is_mandatory) {
                    arrMandatory.push(obj)
                  } else {
                    arrNotMandatory.push(obj)
                  }
                }
              })
              channel.attributes = arrMandatory.concat(arrNotMandatory)
            }
          })
          .finally(() => channel.attrLoading = false)
      }
      emitValue()
    }

    const inputAttribute = (attr, index, channel) => {
      channel.attribute[index] = attr.value
        ? {
            attribute_type: attr.attribute_type,
            attribute_id: attr.attribute_id,
            attribute_value_list: [{
              value_id: channel.code.includes('tiktok')
                ? undefined
                : 0,
              original_value_name: attr.input_validation_type === 'DATE_TYPE'
                ? $moment(attr.value).unix()
                : attr.value,
              value_unit: attr.format_type === 'QUANTITATIVE' && attr.attribute_unit.length
                ? attr.unit
                : undefined,
            }],
            is_mandatory: attr.is_mandatory,
          }
        : ''
      emitValue()
    }

    const selectAttribute = (value, attr, index, channel) => {
      let attribute_value_list = []
      if(isMultiple.value.includes(attr.input_type)) {
        attribute_value_list = value.map(val => {
          return {
            value_id: val.key,
            original_value_name: val.label.trim(),
          }
        })
      } else {
        attribute_value_list = value?.key
          ? [{
              value_id: value.key,
              original_value_name: value.label.trim(),
            }]
          : []
      }
      channel.attribute[index] = attribute_value_list.length
        ? {
            attribute_type: attr.attribute_type,
            attribute_id: attr.attribute_id,
            attribute_name: attr.original_attribute_name,
            attribute_value_list,
            is_mandatory: attr.is_mandatory,
          }
        : ''
      emitValue()
    }

    const emitValue = () => {
      emit('add', marketplaceList.value.map(mp => {
        const { categories, attributes, fields, loading, attrLoading, ...rest } = mp
        return {
          ...rest,
          attribute: rest.attribute.filter(a => a),
        }
      }))
    }

    const isDisabled = (attribute, attr) => {
      if(attribute.value && isMultiple.value.includes(attribute.input_type)) {
        const index = attribute.value.findIndex(val => val.key == attr.value_id)
        return isMultiple.value.includes(attribute.input_type)
          && attribute.value.length == attribute.max_input_value_number
          && index < 0
      }
      return false
    }

    const numberDecimal = (event, attr) => {
      const keyCode = (event.which) ? event.which : event.keyCode
      const { value } = event.target
      if(attr.input_validation_type === 'FLOAT_TYPE') {
        if (!value && keyCode === 46) {
          event.preventDefault()
        }

        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') > -1)) {
          event.preventDefault()
        }

        if (value && value.indexOf('.') > -1 && value.split('.')[1].length > 1) {
          event.preventDefault()
        }
      } else if(attr.input_validation_type === 'INT_TYPE') {
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
          event.preventDefault()
        }
      }
    }

    const selectBrandAttribute = (brand) => {
      if(!brand || !marketplaceList.value.length) return

      const mpIndex = marketplaceList.value.findIndex(mp => mp.code.includes('tokopedia'))
      if(mpIndex > -1) {
        let found = false
        const attrIndex = marketplaceList.value[mpIndex].attributes.findIndex(attr => attr.original_attribute_name.toLowerCase().includes('merek'))
        if(attrIndex > -1) {
          const findValue = marketplaceList.value[mpIndex].attributes[attrIndex].attribute_value_list.find(value => value.value_id == brand || value.original_value_name == brand)
          if(findValue?.value_id) {
            const attributeIndex = marketplaceList.value[mpIndex].attribute.findIndex(attribute => attribute.attribute_name.toLowerCase().includes('merek'))
            if(attributeIndex > -1) marketplaceList.value[mpIndex].attribute.splice(attributeIndex, 1)

            marketplaceList.value[mpIndex].attribute.push({
              attribute_id: 999,
              attribute_name: "Merek",
              attribute_type: undefined,
              attribute_value_list: [{
                original_value_name: findValue.original_value_name,
                value_id: findValue.value_id,
              }],
              is_mandatory: false,
            })
            found = true
          }
        }

        if(!found) {
          const attributeIndex = marketplaceList.value[mpIndex].attribute.findIndex(attribute => attribute.attribute_name.toLowerCase().includes('merek'))
          if(attributeIndex > -1) marketplaceList.value[mpIndex].attribute.splice(attributeIndex, 1)
        }
        
        emitValue()
      }
    }

    return {
      model,
      addChannelModal,
      unselectedChannels,
      selectChannel,
      addChannel,
      removeChannel,
      isSupportedChannel,
      activeChannels,
      marketplaceList,
      isMultiple,
      fetchAttributes,
      inputAttribute,
      selectAttribute,
      numberDecimal,
      isDisabled,
    }
  },
})
</script>

<template>
  <a-card>
    <h4 class="mb-5">
      Publikasi Channel
    </h4>

    <a-alert v-if="!channels.length && !activeChannels.length" type="warning" message="Belum ada channel yang ter-integrasi." />

    <div class="channel-publication--channel-list">
      <div v-for="channel in activeChannels" :key="channel.id" class="">
        <div class="channel-publication--channel-list--item">
          <div>
            <img :src="channel.icon_url || 'https://ik.imagekit.io/powerbiz/no-image.png?tr=w-150,h-150,cm-pad_resize,bg-FFFFFF'" />
          </div>
          <div>
            {{ channel.title }}
          </div>
        </div>

        <a-popconfirm v-if="!channel.exist" title="Apakah Anda ingin menghapus channel ini ?" @confirm="() => removeChannel(channel.id)">
          <a-button class="btn-remove">
            <a-icon type="close" />
          </a-button>
        </a-popconfirm>
      </div>
      <div v-if="unselectedChannels.length" class="add-channel-placeholder" @click="addChannelModal.open = true">
        <a-icon type="plus" />
        <div>Channel</div>
      </div>
    </div>

    <a-card v-if="marketplaceList.length" :title="$t('channel.category.default')" class="channel-category">
      <ValidationObserver ref="validationObserver" slim>
        <div v-for="(channel, idx) in marketplaceList" :key="channel.id">
          <label class="mb-1">
            {{ $t('channel.category.' + channel.code) }}
            <sup class="text-danger">*</sup>
          </label>
          <FormItem
            :name="$t('channel.category.' + channel.code)"
            rules="required"
            class="pcaFormItemLabel"
          >
            <a-spin :spinning="channel.loading">
              <a-cascader
                v-model="channel.category"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :field-names="channel.fields"
                :options="channel.categories"
                :disabled="channel.isMapping"
                placeholder=""
                @change="fetchAttributes($event, channel)"
              />
            </a-spin>
          </FormItem>
          <div>
            <a-row v-if="channel.attrLoading" :gutter="16">
              <a-col :span="12">
                <a-skeleton :loading="channel.attrLoading" active />
              </a-col>
              <a-col :span="12">
                <a-skeleton :loading="channel.attrLoading" active />
              </a-col>
            </a-row>
            <a-row v-else :gutter="16">
              <template v-for="(attribute, index) in channel.attributes">
                <a-col
                  v-if="!attribute.original_attribute_name.toLowerCase().includes('merek')"
                  :key="attribute.attribute_id"
                  :span="12"
                >
                  <label class="mb-1">
                    {{ attribute.display_attribute_name }}
                    <sup v-if="attribute.is_mandatory" class="text-danger">*</sup>
                  </label>
                  <FormItem
                    :name="attribute.display_attribute_name"
                    :rules="attribute.is_mandatory ? 'required' : ''"
                    class="pcaFormItemLabel"
                  >
                    <template v-if="attribute.input_type === 'TEXT_FILED'">
                      <a-date-picker
                        v-if="attribute.input_validation_type === 'DATE_TYPE'"
                        v-model="attribute.value"
                        format="DD/MM/YYYY"
                        size="large"
                        class="w-100"
                        @change="inputAttribute(attribute, index, channel)"
                      />
                      <a-row v-else>
                        <a-col :span="attribute.format_type === 'QUANTITATIVE' && attribute.attribute_unit.length ? 18 : 24">
                          <a-input
                            v-model="attribute.value"
                            size="large"
                            @change="inputAttribute(attribute, index, channel)"
                            @keypress="numberDecimal($event, attribute)"
                          />
                        </a-col>
                        <a-col v-if="attribute.format_type === 'QUANTITATIVE' && attribute.attribute_unit.length" :span="6">
                          <a-select
                            v-model="attribute.unit"
                            size="large"
                            class="quantitative"
                            style="margin-left: -3px"
                            @change="inputAttribute(attribute, index, channel)"
                          >
                            <a-select-option
                              v-for="unit in attribute.attribute_unit"
                              :key="unit"
                              :value="unit"
                            >
                              {{ unit }}
                            </a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>
                    </template>
                    <a-select
                      v-else
                      v-model="attribute.value"
                      :mode="attribute.mode"
                      size="large"
                      class="select-antd-default"
                      label-in-value
                      allow-clear
                      @change="selectAttribute($event, attribute, index, channel)"
                    >
                      <a-select-option
                        v-for="attr in attribute.attribute_value_list"
                        :key="attr.value_id"
                        :value="attr.value_id"
                        :disabled="isDisabled(attribute, attr)"
                      >
                        {{ attr.display_value_name }}
                      </a-select-option>
                    </a-select>
                  </FormItem>
                </a-col>
              </template>
            </a-row>
          </div>
          <a-divider v-if="idx != (marketplaceList.length - 1)" style="margin-top: 12px" />
        </div>
      </ValidationObserver>
    </a-card>

    <a-modal
      v-model="addChannelModal.open"
      :width="550"
      title="Pilih Channel"
      :footer="false"
    >
      <div class="channel-publication--channel-list">
        <template v-for="channel in unselectedChannels">
          <div
            v-if="channel.supported"
            :key="channel.id"
            class="selectable" 
            :class="{
              selected: addChannelModal.selected.includes(channel.id),
              unsupported: !channel.supported,
            }"
            @click="() => channel.supported ? selectChannel(channel.id) : null"
          >
            <div class="channel-publication--channel-list--item">
              <div>
                <img :src="channel.icon_url || 'https://ik.imagekit.io/powerbiz/no-image.png?tr=w-150,h-150,cm-pad_resize,bg-FFFFFF'" />
              </div>
              <div>
                {{ channel.title }}
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="d-flex mt-5" style="gap: 1rem">
        <a-button block size="large" type="link" @click="addChannelModal.open = false">
          Batal
        </a-button>
        <a-button
          block
          size="large"
          type="primary"
          :disabled="!addChannelModal.selected.length"
          @click="() => addChannel()"
        >
          Tambahkan Channel
        </a-button>
      </div>
    </a-modal>
  </a-card>
</template>


<style lang="scss">
.channel-publication--channel-list {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;

  > div {
    width: 150px;
    aspect-ratio: 1;
    position: relative;

    img {
      max-height: 60px;
      margin-bottom: 1rem;
    }
  }

  &--item {
    border-radius: .75rem;
    overflow: hidden;
    border: 1px solid #dedede;
    text-align: center;
    padding: 1.5rem 1rem;
    font-size: .85rem;
    line-height: 1.25;
    height: 100%;
    box-shadow: 0px 20px 20px -20px rgba(50, 50, 93, 0.15);
  }

  .selectable {
    cursor: pointer;

    &.selected {
      .channel-publication--channel-list--item {
        box-shadow: none;
        background-color: #efefef;
        border-color: var(--kit-color-primary);
      }
    }

    &.unsupported {
      .channel-publication--channel-list--item {

        &::after {
          content: 'Belum didukung';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, .85);
        }
      }
    }
  }

  .btn-remove {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    aspect-ratio: 1;
    height: 28px;
    height: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
    color: #ccc;
    background: #4d4d4d;
    cursor: pointer;
    border-radius: 20rem;

    &:hover {
      color: #fff;
      background: var(--danger);
      border-color: var(--danger);
    }
  }

  .add-channel-placeholder {
    border-radius: .75rem;
    overflow: hidden;
    border: 1.5px dashed #ccc;
    background-color: #fff;
    color: #999999;
    cursor: pointer;
    font-size: 1.1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    .anticon {
      font-size: 2rem;
      margin-bottom: .5rem;
    }
  }
}

.channel-category {
  margin-top: 1.5rem !important;

  .ant-card-head {
    font-size: 14px !important;
    background-color: #fafafa !important;
  }

  .ant-card-head-title {
    padding: 14px 0 !important;
  }
}

.quantitative {
  .ant-select-selection {
    background-color: #f0f2f4;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>

