<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import FormItem from '@/components/Input/FormItem.vue'
import TextEditor from '@/components/Input/TextEditor/index.vue'
import { useSaveSectionOnScroll } from './useSaveSectionOnScroll'
import Checkbox from '@/components/Input/Checkbox.vue'

export default defineComponent({
  components: {
    FormItem,
    TextEditor,
    ValidationObserver,
    Checkbox,
},
  props: {
    value: {
      type: Object,
      required: true,
    },
    brands: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      required: true,
    },
    businessId: {
      type: String,
      default: '',
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { el, dirty, validationObserver } = useSaveSectionOnScroll(emit)

    /**
     * @type {import('@vue/composition-api').Ref<import('@/types/product').ProductFormModel['detail']>}
     */
    const model = ref(props.value.detail)
    const filteredBrands = ref(props.brands)
    const channelList = ref([])
    watch(() => props.value, () => {
      model.value = props.value.detail
    }, { deep: true })

    const subRecurse = (subLists) => {
      let subParent = []
      subLists.forEach(subList => {
        let prop = 'child'
        let subChildren = []
        const { id, name, slug } = subList
        if (subList.children) {
          subChildren = subRecurse(subList.children)
          if (subChildren.length) prop = 'children'
        }
        subParent.push({
          value: id,
          label: name,
          slug,
          [prop]: subChildren,
        })
      })
      return subParent
    }

    const recurse = (lists) => {
      let parent = []
      lists.forEach(list => {
        let prop = 'child'
        let children = []
        const { id, name, slug } = list
        if (list.children) {
          children = subRecurse(list.children)
          if (children.length) prop = 'children'
        }
        parent.push({
          value: id,
          label: name,
          slug: slug,
          [prop]: children,
        })
      })
      return parent
    }

    const categoryOptions = computed(() => props.categories)
    const rulesProductName = ref('required')

    watch(() => props.value.channels, () => {
      const filteredPropsChannels = props.value.channels.filter((obj) => !obj.deleted)
      if (filteredPropsChannels?.length > 0) {
        rulesProductName.value = 'required|min:25'
      } else {
        rulesProductName.value = 'required'
      }
    }, { deep: true })

    watch(() => props.brands, () => {
      if (props.brands.length > 0) {
        filteredBrands.value = props.brands
      }
    }, { deep: true })

    const filterOption = (input, option) => {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    }

    return {
      model,
      categoryOptions,
      rulesProductName,
      validationObserver,
      el,
      dirty,
      filteredBrands,
      filterOption,
    }
  },
  data() {
    return {
      endOpen: false,
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.model.end_period
      if (!startValue || !endValue) return startValue && startValue < this.$moment().startOf('day')
      return (startValue && startValue.valueOf() < this.$moment().startOf('day')) || (startValue.valueOf() > endValue.valueOf())
    },
    disabledEndDate(endValue) {
      const startValue = this.model.start_period
      if (!endValue || !startValue) return endValue && endValue < this.$moment().startOf('day')
      return (endValue && endValue.valueOf() < this.$moment().startOf('day')) || (startValue.valueOf() >= endValue.valueOf())
    },
    onStartOpenChange(open) {
      if (!open) this.endOpen = true
    },
    onEndOpenChange(open) {
      this.endOpen = open
    },
  },
})
</script>

<template>
  <ValidationObserver ref="validationObserver" slim>
    <a-card ref="el">
      <h4 class="mb-5">
        {{ $t('product.product_details') }}
      </h4>

      <section>
        <div class="font-weight-semibold mb-4">
          {{ $t('product.product_information') }}
        </div>
        <div>
          <label class="mb-1">
            {{ $t('product.product_name') }} <sup class="text-danger">*</sup>
          </label>
          <!-- :label="`${$t('product.product_name')} *`" -->
          <FormItem
            :name="$t('product.product_name')"
            vid="title"
            :rules="rulesProductName"
            class="pcaFormItemLabel"
          >
            <!-- :placeholder="`${$t('product.product_name')} *`" -->
            <a-input
              v-model="model.title"
              class="h-48px"
              focus
            />
          </FormItem>

          <a-row v-if="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'" :gutter="24">
            <a-col :span="12">
              <label class="mb-1">
                {{ $t('discount.start_date') }} <sup class="text-danger">*</sup>
              </label>
              <FormItem
                :name="$t('discount.start_date')"
                vid="start_date"
                rules="required"
              >
                <!-- :placeholder="`${$t('product.product_name')} *`" -->
                <a-date-picker
                  v-model="model.start_period"
                  show-time
                  size="large"
                  class="w-100"
                  format="DD MMM YYYY HH:mm:ss"
                  :placeholder="$t('discount.start_date')"
                  :disabled-date="disabledStartDate"
                  @openChange="onStartOpenChange"
                />
              </FormItem>
            </a-col>
            <a-col :span="12">
              <label class="mb-1">
                {{ $t('discount.end_date') }} <sup class="text-danger">*</sup>
              </label>
              <FormItem
                :name="$t('discount.end_date')"
                vid="title"
                rules="required"
                class="pcaFormItemLabel"
              >
                <!-- :placeholder="`${$t('product.product_name')} *`" -->
                <a-date-picker
                  v-model="model.end_period"
                  show-time
                  size="large"
                  class="w-100"
                  format="DD MMM YYYY HH:mm:ss"
                  :open="endOpen"
                  :placeholder="$t('discount.end_date')"
                  :disabled-date="disabledEndDate"
                  @openChange="onEndOpenChange"
                />
              </FormItem>
            </a-col>
          </a-row>
          
          <label class="mb-1">
            {{ $t('product.categories') }} <sup class="text-danger">*</sup>
          </label>
          <FormItem
            :name="$t('product.categories')"
            vid="category"
            rules="required"
          >
            <!-- :placeholder="`${$t('product.categories')} *`" -->
            <a-input-group compact class="">
              <a-cascader
                v-model="model.category_id"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :field-names="{ label: 'name', value: 'id', children: 'children' }"
                :options="categoryOptions"
                placeholder=""
              />
            </a-input-group>
          </FormItem>

          <label class="mb-1">
            {{ $t('product.brand') }}
          </label>
          <FormItem
            :name="$t('product.brand')"
            vid="brand"
          >
            <a-select
              v-if="filteredBrands.length > 0"
              v-model="model.brand_id"
              size="large"
              class="select-antd-default"
              placeholder="Cari & Pilih Brand Produk"
              :filter-option="filterOption"
              allow-clear
              show-search
            >
              <a-select-option v-for="brd in filteredBrands" :key="brd.id">
                {{ brd.title }}
              </a-select-option>
            </a-select>
            <!-- :placeholder="$t('product.brand')" -->
            <a-input
              v-else
              v-model="model.brand_id"
              class="h-48px"
            />
          </FormItem>

          <a-row class="mt-5">
            <a-col :sm="{ span: 8 }">
              <div class="mb-3">
                <label class="d-block font-weight-semibold mb-3">{{ $t('product.item_condition') }}</label>
                <FormItem>
                  <Checkbox
                    v-model="model.condition"
                    check-value="USED"
                    uncheck-value="NEW"
                    :label="$t('product.used_condition')"
                    class="ml-2"
                  />
                </FormItem>
              </div>
            </a-col>
            <a-col :sm="{ span: 8 }">
              <div class="mb-3">
                <label class="d-block font-weight-semibold mb-3">{{ $t('product.importLabel') }}</label>
                <FormItem>
                  <Checkbox v-model="model.imported" :label="$t('product.import')" class="ml-2" />
                </FormItem>
              </div>
            </a-col>
          </a-row>

          <div v-if="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'">
            <label class="mb-1">
              {{ $t('product.short_description') }}
            </label>
            <FormItem
              :name="$t('product.short_description')"
              vid="brand"
            >
              <a-input
                v-model="model.short_description"
                class="h-48px"
                placeholder="Maks. 70 Karakter"
              />
            </FormItem>
          </div>

          <div class="mt-4">
            <label class="d-block font-weight-semibold mb-3">{{ $t('product.description') }}</label>
            <FormItem :name="$t('product.description')" vid="description" rules="required|min:25">
              <TextEditor
                v-model="model.long_description"
                :placeholder="$t('product.write_something')"
              />
            </FormItem>
          </div>
        </div>
      </section>
    </a-card>
  </ValidationObserver>
</template>

<style lang="scss">
.kategori-options ul.ant-cascader-menu {
  height: auto;
  max-height: 180px;
}
</style>
