<script>
import { defineComponent, computed, getCurrentInstance } from 'vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import FormItem from '@/components/Input/FormItem.vue'
import BundleItemInput from './BundleItemInput.vue'
import { ValidationProvider } from 'vee-validate'
import BundleTypeInput from './BundleTypeInput.vue'

/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */

/**
 * Define component
 */
export default defineComponent({
  components: {
    Checkbox,
    FormItem,
    BundleItemInput,
    ValidationProvider,
    BundleTypeInput,
},
  props: {
    value: {
      // /** @type {import('@/types/product').ProductBundle} */
      type: [Object],
      default: () => null,
    },
    product: {
      // /** @type {import('vue').PropType<ProductFormModel} */
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $confirm } = getCurrentInstance().proxy.$root

    const isProductBundling = computed(() => !!props.value?.bundles.length || props.value?.type)
    const toggleIsProductBundling = () => {
      if (isProductBundling.value &&  props.value.bundles.length) {
        return $confirm({
          title: 'Hapus Bundling',
          content: 'Jika Anda rubah, daftar produk bundling akan terhapus lho, apakah Anda yakin?',
          onOk: () => {
            emit('input', {
              type: isProductBundling.value ? null : (props.value?.type || 'SOFT'),
              bundles: isProductBundling.value ? [] : props.value?.bundles,
            })
          },
        })
      }

      emit('input', {
        type: isProductBundling.value ? null : (props.value?.type || 'SOFT'),
        bundles: isProductBundling.value ? [] : props.value?.bundles,
      })
    }

    const onBundleItemInput = (values) => {
      emit('input', {
        ...props.value,
        bundles: values,
      })
    }

    return {
      isProductBundling,
      toggleIsProductBundling,
      onBundleItemInput,
    }
  },
})
</script>

<template>
  <div>
    <FormItem>
      <Checkbox
        :value="isProductBundling"
        :disabled="!!$route.query.id"
        label="Product Bundling"
        @input="toggleIsProductBundling"
      />
    </FormItem>

    <div v-if="isProductBundling">
      <div class="mb-1">
        <FormItem>
          <BundleTypeInput v-model="value.type" />
        </FormItem>
      </div>

      <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        rules="required"
        name="Bundling item"
        style="max-width: 700px"
      >
        <div v-if="errors.length" class="ant-form-explain text-danger mb-2">
          {{ errors[0] }}
        </div>
        <BundleItemInput
          :value="value.bundles"
          :business="business"
          :product="product"
          @input="onBundleItemInput"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
